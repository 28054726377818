import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import ServiceNav from '../components/ServiceNav'
import { Icon } from '../components/builders/icon-builder'
import { Logo } from '../types/widget.types'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Sticky, StickyContainer } from 'react-sticky'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { IconText } from '../types/widget.types'
import { t } from 'ttag'
import SibForm from '../components/sib-forms/eshops'
import TopImageBlue from '../components/TopImageBlue'
import TopImageGreen from '../components/TopImageGreen'
import BreadCrumbs from '../components/BreadCrumbs'
import FormBuilder from '../components/builders/form-builder'
import JirkaPhoto from '../img/jirka.png'
import useWindowDimensions from '../utils/useWindowDimensions'
import { getLocalizedUrl } from '../utils/localeURL'

const Service = ({ data, pageContext }: any) => {
  const page = data.orchard.puxDesignServicePage[0]
  const respoTabsAnchor = React.useRef<HTMLDivElement>(null)
  const [isPictureLoaded, setPictuLoaded] = React.useState(false)

  let { LOCALE } = process.env
  LOCALE = LOCALE ? LOCALE : `cs`

  const handlePictureLoad = () => {
    setPictuLoaded(true)
  }

  const topImageProps = {
    title: page.servicePageTopImageDescription?.html ? page.servicePageTopImageDescription?.html : `missing title`,
    icon: page.puxDesignIconSelector.puxDesignIconSelectorIcon,
    animationStyle: page.servicePageAnimation,
    onLoad: handlePictureLoad,
  }

  const handleScroll = () => {
    const anchors = Array.from(document.querySelectorAll(".relative-anchor[id]"))
    const offset = window.pageYOffset;

    const filtered = anchors.filter(anchor => anchor.getBoundingClientRect().y > 5)
    const position = anchors.length - filtered.length;

    Array.from(document.querySelectorAll(`.service-nav .btn-link`)).map(link => link.classList.remove("active"));

    if (position) {
      const id = anchors[position - 1].getAttribute('id');
      document.querySelector(`.service-nav .btn-link[href*='#${id}']`)?.classList.add('active')
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs
        theme={page.puxDesignThemeSelector.puxDesignThemeSelectorTheme}
        items={pageContext.breadcrumbs}
        center={false}
      />
      <section id="top">
        {page.puxDesignThemeSelector.puxDesignThemeSelectorTheme === `theme-green` ? (
          <TopImageGreen {...topImageProps} />
        ) : (
          <TopImageBlue {...topImageProps} />
        )}
      </section>

      <div className={`${page.puxDesignThemeSelector.puxDesignThemeSelectorTheme} secondary-nav-helper`}>
        <StickyContainer>
          <div className="nav-negative-margin">
            <Sticky bottomOffset={-100} topOffset={-85}>
              {({ style, isSticky }: { style: React.CSSProperties; isSticky: boolean }) => (
                <div className={`service-nav-wrap` + (isSticky ? ` fixed` : ``)} style={style}>
                  <div className="pux-container">
                    <div className="service-nav">
                      <div className="page-context">
                        <span>{page.displayText}</span>
                        <AnchorLink to={`${getLocalizedUrl(page.path)}#top`} className={`scroll-to-top${isSticky ? ' show-anchor' : ''}`}></AnchorLink>
                      </div>
                      {renderNav(page.path, page.puxDesignServicePageNavigation.contentItems)}
                      <AnchorLink
                        to={`${getLocalizedUrl(page.path)}#sib-form-scroll-helper`}
                        className="btn btn-primary btn-big"
                      >{t`PuxDesign.Service.ContactUs`}</AnchorLink>
                    </div>
                  </div>
                </div>
              )}
            </Sticky>
          </div>
          <WidgetBuilder widgetBuilderData={pageContext.widgetsTop} />

          {page.puxDesignServicePageTabs?.contentItems.length ? (
            <section className="bg-grey negative-bottom">
              <div className="pux-container">
                <Tabs>
                  <TabList>
                    {page.puxDesignServicePageTabs.contentItems.map((tab: TabProps) => (
                      <Tab key={tab.contentItemId} onClick={() => scrollToContentIfMobile(respoTabsAnchor)}>
                        <span className="btn-link">{tab.puxDesignTabTab}</span>
                      </Tab>
                    ))}
                  </TabList>

                  <div ref={respoTabsAnchor} className="mobile-anchor"></div>
                  {page.puxDesignServicePageTabs.contentItems.map((tab: TabProps) => (
                    <TabPanel key={tab.contentItemId}>
                      <div className="icons-text">
                        {tab.bag.contentItems.map((item: IconText) => (
                          <div className="icon-text" key={item.contentItemId}>
                            <div className="icon">
                              <Icon
                                id={item.puxDesignIconSelector.puxDesignIconSelectorIcon}
                                color={item.puxDesignIconSelector.puxDesignIconSelectorColor}
                              />
                            </div>
                            <div
                              className="text"
                              dangerouslySetInnerHTML={{
                                __html: item.puxDesignIconTextText.html?.replace(/font-size: 1rem;/g, ``),
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </section>
          ) : null}

          <WidgetBuilder widgetBuilderData={pageContext.widgetsBottom} />
        </StickyContainer>

        <section className={`triangle-cross service-form-section`}>
          <div id="sib-form-scroll-helper" className="relative-anchor"></div>
          <div className="pux-container narrow service-form-wrapper">
            <div className="form-info">
              <h2>{page.servicePageFormHeading}</h2>
            </div>
            <FormBuilder formId={page.servicePageForm} recaptchaUrl={pageContext.recaptchaUrl} />
            {
              page.servicePageFormPerson ?
                <>
                  <div className="info-tom-wrap">
                    <div className="info-tom">
                      <img
                        width={60}
                        height={60}
                        src={page.servicePageFormPerson.contentItems[0].puxMediaSelector.puxMediaSelectorImage.resizePaths[0]}
                        alt={page.servicePageFormPerson.contentItems[0].displayText}
                      />
                      <strong>{page.servicePageFormPerson.contentItems[0].displayText}</strong>
                      {
                        page.servicePageFormPerson.contentItems[0].puxDesignPersonPosition ?
                          ` / ${page.servicePageFormPerson.contentItems[0].puxDesignPersonPosition}` :
                          ''
                      }
                    </div>
                    <div className="info-tom-phone">
                      <a href={`tel:${page.servicePageFormPerson.contentItems[0].puxDesignPersonPhone}`}>
                        <Icon id="mobile" color="#FFFFFF" />
                        {page.servicePageFormPerson.contentItems[0].puxDesignPersonPhone}
                      </a>
                    </div>
                    <div className="info-tom-mail">
                      <a href={`mailto:${page.servicePageFormPerson.contentItems[0].puxDesignPersonMail}`}>
                        <Icon id="mail" color="#FFFFFF" />
                        <span className="btn-link">{page.servicePageFormPerson.contentItems[0].puxDesignPersonMail}</span>
                      </a>
                    </div>
                  </div>
                </> :
                <>
                  <div className="info-tom-wrap">
                    <div className="info-tom">
                      <img src={JirkaPhoto} alt="Jiří Juhaňák"></img>
                      <strong>
                        Jiří Juhaňák
                      </strong>
                      <span> / {`${LOCALE === 'cs' ? 'KONZULTANT' : 'CONSULTANT'}`}</span>
                    </div>
                    <div className="info-tom-phone">
                      <a href="tel:+420605175519">
                        <Icon id="mobile" color="#FFFFFF" />
                        +420 605 175 519
                      </a>
                    </div>
                    <div className="info-tom-mail">
                      <a href="mailto:jiri.juhanak@puxdesign.cz">
                        <Icon id="mail" color="#FFFFFF" />
                        <span className="btn-link">jiri.juhanak@puxdesign.cz</span>
                      </a>
                    </div>
                  </div>
                </>
            }
          </div>
        </section>
      </div>
      <section>
        <div className="pux-container center-content">
          <h2>{page.servicePageBottomHeading ? page.servicePageBottomHeading : t`PuxDesign.Service.OtherServices.Heading`}</h2>
        </div>
      </section>
      <ServiceNav {...page.puxDesignServicePageCrossNavigation}></ServiceNav>
    </Layout>
  )
}

const scrollToContentIfMobile = (ref: React.RefObject<HTMLDivElement>): void => {
  const { width } = useWindowDimensions()

  if (width < 992 && ref.current) {
    ref.current.scrollIntoView({ behavior: `smooth` })
  }
}

interface TabProps {
  contentItemId: string
  puxDesignTabTab: string
  bag: {
    contentItems: [IconText]
  }
}

interface NavItemProps {
  contentItemId: string
  puxDesignNavigationItemAnchor: string
  puxDesignNavigationItemText: string
}

const renderNav = (path: string, items: [NavItemProps]) => {
  return (
    <nav>
      {items.map(item => {
        return (
          <AnchorLink
            className="btn-link"
            to={`${getLocalizedUrl(path)}#${item.puxDesignNavigationItemAnchor}`}
            title={item.puxDesignNavigationItemText}
            key={item.contentItemId}
          >
            {item.puxDesignNavigationItemText}
          </AnchorLink>
        )
      })}
    </nav>
  )
}

export default Service

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignServicePage(first: 1, where: { path: $pagePath }) {
        path
        displayText
        servicePageAnimation
        servicePageForm
        servicePageFormHeading
        servicePageFormDescription
        servicePageBottomHeading
        localization {
          localizations {
            ... on Orchard_PuxDesignServicePage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        servicePageFormPerson {
          contentItems {
            ... on Orchard_PuxDesignPerson {
              displayText
              puxDesignPersonPhone
              puxDesignPersonMail
              puxDesignPersonPosition
              puxMediaSelector {
                puxMediaSelectorImage {
                  resizePaths(width: 80)
                }
              }
            }
          }
        }
        servicePageTopImageDescription {
          html
        }
        puxCategory {
          termContentItems {
            displayText
          }
        }
        puxDesignThemeSelector {
          puxDesignThemeSelectorTheme
        }
        puxDesignIconSelector {
          puxDesignIconSelectorIcon
          puxDesignIconSelectorColor
        }
        puxDesignServicePageNavigation {
          contentItems {
            ... on Orchard_PuxDesignNavigationItem {
              contentItemId
              puxDesignNavigationItemAnchor
              puxDesignNavigationItemText
            }
            contentItemId
          }
        }
        puxDesignServicePageTabs {
          contentItems {
            contentItemId
            ... on Orchard_PuxDesignTab {
              displayText
              puxDesignTabTab
              contentItemId
              bag {
                contentItems {
                  ... on Orchard_PuxDesignIconText {
                    contentItemId
                    puxDesignIconSelector {
                      puxDesignIconSelectorIcon
                      puxDesignIconSelectorColor
                    }
                    puxDesignIconTextText {
                      html
                    }
                  }
                }
              }
            }
          }
        }
        puxDesignServicePageCrossNavigation {
          contentItems {
            ... on Orchard_PuxDesignServiceNavigationItem {
              puxDesignServiceNavigationItemCustomDescription
              puxDesignServiceNavigationItemCustomTitle
              puxDesignServiceNavigationItemService {
                contentItems {
                  ... on Orchard_PuxDesignServicePage {
                    displayText
                    puxDesignServicePageDescription
                    path
                    puxDesignIconSelector {
                      puxDesignIconSelectorIcon
                    }
                    puxDesignThemeSelector {
                      puxDesignThemeSelectorTheme
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
